import React, { useState } from 'react';
import swal from 'sweetalert';
import '../estilos/FormularioProductosServicios.css';

function FormularioProductoServicio({ cerrarFormulario, actualizarProductosServicios }) {
  // Estados para los datos del producto o servicio
  const [nombre, setNombre] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [precio, setPrecio] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const nuevoProductoServicio = {
      nombre,
      descripcion,
      precio,
    };
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/productos-servicios`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(nuevoProductoServicio),
      });
  
      if (response.ok) {
        swal({
          title: "Producto o servicio creado exitosamente",
          icon: "success",
          timer: 2000,
          buttons: false,
          className: 'swal-center'
        });
        limpiarFormulario();
        cerrarFormulario();
        actualizarProductosServicios(); 
      } else {
        swal({
          title: "Error al crear el producto o servicio",
          icon: "error",
          timer: 2000,
          buttons: false,
          className: 'swal-center'
        });
      }
    } catch (error) {
      console.error('Error:', error);
      swal({
        title: "Error al conectar con el servidor",
        icon: "error",
        timer: 2000,
        buttons: false,
        className: 'swal-center'
      });
    }
  };
  

  const limpiarFormulario = () => {
    setNombre('');
    setDescripcion('');
    setPrecio('');
  };

  return (
    <div className="formulario-producto-servicio">
      <button className="cerrar-formulario" onClick={cerrarFormulario}>
        X
      </button>
      <form onSubmit={handleSubmit}>
          <div className="columna">
            <h3>Datos del Producto o Servicio</h3>
            <div>
              <label>Nombre:</label>
              <input
                type="text"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Descripción:</label>
              <textarea
                value={descripcion}
                onChange={(e) => setDescripcion(e.target.value)}
                required
              ></textarea>
            </div>
            <div>
              <label>Precio:</label>
              <input
                type="number"
                step="0.01"
                value={precio}
                onChange={(e) => setPrecio(e.target.value)}
                required
              />
            </div>
            <div className="formulario-botones">
          <button className='btnFormulario' type="submit">Guardar</button>
          <button className='btnFormulario' type="button" onClick={limpiarFormulario}>
            Limpiar
          </button>
        </div>
          </div>
        
        
      </form>
    </div>
  );
}

export default FormularioProductoServicio;