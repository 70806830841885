import React, { useState } from 'react';
import '../estilos/Opciones.css';
import iconNuevoServicio from '../recursos/agregar.png';
import iconListado from '../recursos/Listado.png';
import iconClientes from '../recursos/Clientes.png';
import iconProductos from '../recursos/Productos.png';
import iconInformes from '../recursos/Informes.png';
import iconEstadisticas from '../recursos/Estadisticas.png';
import iconLavador from '../recursos/lavador.png';

function Opciones({ manejarSeleccion, usuarioInfo }) {
  const [seleccionado, setSeleccionado] = useState(null);

  const opciones = [
    { icono: iconNuevoServicio, nombre: 'Nuevo Servicio' },
    { icono: iconListado, nombre: 'Servicios' },
    { icono: iconClientes, nombre: 'Clientes' },
    { icono: iconProductos, nombre: 'Productos y Servicios', rol: 'admin' },
    { icono: iconLavador, nombre: 'Lavadores', rol: 'admin' },
    { icono: iconEstadisticas, nombre: 'Crear Usuarios', rol: 'admin' },
    { icono: iconInformes, nombre: 'Informes', rol: 'admin' },
  ];

  const manejarClick = (index, nombre) => {
    setSeleccionado(index);
    manejarSeleccion(nombre); 
  };

  return (
    <div className="opciones">
      <div className="lista-opciones">
        {opciones.map((opcion, index) => (
          (opcion.rol === undefined || opcion.rol === usuarioInfo.rol) && (
            <button
              key={index}
              className={`boton-opcion ${seleccionado === index ? 'seleccionado' : ''}`}
              onClick={() => manejarClick(index, opcion.nombre)}
            >
              <img src={opcion.icono} alt={opcion.nombre} className="icono" />
              <span>{opcion.nombre}</span>
            </button>
          )
        ))}
      </div>
    </div>
  );
}

export default Opciones;