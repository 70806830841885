import React, { useState } from 'react';
import swal from 'sweetalert';
import '../estilos/FormularioProductosServicios.css';

function FormularioLavador({ cerrarFormulario }) {
  const [nombre, setNombre] = useState('');
  const [cedula, setCedula] = useState('');
  const [telefono, setTelefono] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const nuevoLavador = {
      nombre,
      cedula,
      telefono,
    };
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/lavadores`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(nuevoLavador),
      });
  
      if (response.ok) {
        swal({
          title: "Lavador agregado exitosamente",
          icon: "success",
          timer: 2000,
          buttons: false,
        });
        limpiarFormulario();
        cerrarFormulario();
      } else {
        const errorData = await response.json();
        console.error('Error al guardar el lavador:', errorData);
        swal({
          title: "Error al guardar el lavador",
          text: errorData.message,
          icon: "error",
          timer: 5000,
          buttons: false,
        });
      }
    } catch (error) {
      console.error('Error al guardar el lavador:', error);
      swal({
        title: "Error al conectar con el servidor",
        icon: "error",
        timer: 2000,
        buttons: false,
      });
    }
  };
  
  const limpiarFormulario = () => {
    setNombre('');
    setCedula('');
    setTelefono('');
  };
  

  return (
    <div className="formulario-lavador">
      <div className="formulario-header">
        <h2>Agregar Lavador</h2>
        <button className="cerrar-formulario" onClick={cerrarFormulario}>X</button>
      </div>
      <form onSubmit={handleSubmit}>
      <div className="columna">
        <div>
          <label>Nombre: </label>
          <input
            type="text"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Cédula: </label>
          <input
            type="text"
            value={cedula}
            onChange={(e) => setCedula(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Teléfono: </label>
          <input
            type="text"
            value={telefono}
            onChange={(e) => setTelefono(e.target.value)}
            required
          />
        </div>
        <div className="formulario-botones">
          <button className='btnFormulario' type="submit">Guardar</button>
          <button className='btnFormulario' type="button" onClick={limpiarFormulario}>
            Limpiar
          </button>
        </div>
        </div>     
      </form>
    </div>
  );
}

export default FormularioLavador;