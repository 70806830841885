import React, { useState} from 'react';
import axios from 'axios';
import '../estilos/Informes.css';
import ServiciosHoy from '../recursos/ServiciosHoy.jpg';
import ClientesRecurrentes from '../recursos/ClientesRecurrentes.jpg';
import IngresosGenerados from '../recursos/IngresosGenerados.png';
import Tendencias from '../recursos/Tendencias.png';
import Estadisticas from '../recursos/Estadisticas.png';
import regresar from '../recursos/regresar.png';

const Informes = ({ usuarioInfo, cerrarFormulario }) => {
  const [contenido, setContenido] = useState(''); // Estado para controlar el contenido mostrado
  const [servicios, setServicios] = useState([]); // Estado para almacenar la información de "Servicios de Hoy"
  const [serviciosMes, setServiciosMes] = useState([]); // Estado para almacenar la información de "Servicios del Mes"
  const [clientesRecurrentes, setClientesRecurrentes] = useState([]); // Estado para almacenar la información de "Clientes Recurrentes"
  const [ingresosGenerados, setIngresosGenerados] = useState([]); // Estado para almacenar la información de "Ingresos Generados"
  const [ingresosGeneradosSemana, setIngresosGeneradosSemana] = useState([]); // Estado para almacenar la información de "Ingresos Generados Semana"
  const [ingresosGeneradosMes, setIngresosGeneradosMes] = useState([]); // Estado para almacenar la información de "Ingresos Generados Mes"
  const [ingresosGeneradosTrimestre, setIngresosGeneradosTrimestre] = useState([]); // Estado para almacenar la información de "Ingresos Generados Trimestre"
  const [ingresosGeneradosSemestre, setIngresosGeneradosSemestre] = useState([]); // Estado para almacenar la información de "Ingresos Generados Semestre"

  // Establecer baseURL de Axios usando la variable de entorno
axios.defaults.baseURL = process.env.REACT_APP_API_URL || "/api";

// Función para manejar la solicitud al backend y actualizar el estado
const fetchServiciosHoy = () => {
  axios.get('/servicios/del-dia') // Nota: No es necesario usar '/api', ya se establece en axios.defaults.baseURL
    .then(response => {
      setServicios(response.data);
      setContenido('servicios');
    })
    .catch(error => {
      console.error('Error al obtener los servicios de hoy:', error);
    });

  axios.get('/servicios/por-mes')
    .then(response => {
      setServiciosMes(response.data);
    })
    .catch(error => {
      console.error('Error al obtener los servicios del mes:', error);
    });
};

// Función para manejar la solicitud al backend y actualizar el estado
const fetchClientesRecurrentes = () => {
  axios.get('/servicios/clientes-recurrentes-mes')
    .then(response => {
      setClientesRecurrentes(response.data);
      setContenido('clientesRecurrentes');
    })
    .catch(error => {
      console.error('Error al obtener los clientes recurrentes:', error);
    });
};

const fetchIngresosGenerados = () => {
  axios.get('/ingresos-generados')
    .then(response => {
      setIngresosGenerados(response.data);
    })
    .catch(error => {
      console.error('Error al obtener los ingresos generados:', error);
    });

  axios.get('/ingresos-generados-semana')
    .then(response => {
      setIngresosGeneradosSemana(response.data);
    })
    .catch(error => {
      console.error('Error al obtener los ingresos generados de la semana:', error);
    });

  axios.get('/ingresos-generados-mes')
    .then(response => {
      setIngresosGeneradosMes(response.data);
    })
    .catch(error => {
      console.error('Error al obtener los ingresos generados del mes:', error);
    });

  axios.get('/ingresos-generados-trimestre')
    .then(response => {
      setIngresosGeneradosTrimestre(response.data);
    })
    .catch(error => {
      console.error('Error al obtener los ingresos generados del trimestre:', error);
    });

  axios.get('/ingresos-generados-semestre')
    .then(response => {
      setIngresosGeneradosSemestre(response.data);
    })
    .catch(error => {
      console.error('Error al obtener los ingresos generados del semestre:', error);
    });

  setContenido('ingresosGenerados');
};

// Función para manejar el cierre del contenido actual y mostrar los botones nuevamente
const handleCerrar = () => {
  setContenido('');
  cerrarFormulario();
};

const botones = [
  { nombre: 'Servicios', imagen: ServiciosHoy, onClick: fetchServiciosHoy },
  { nombre: 'Ingresos Generados', imagen: IngresosGenerados, onClick: fetchIngresosGenerados },
  { nombre: 'Clientes Recurrentes', imagen: ClientesRecurrentes, onClick: fetchClientesRecurrentes },
  { nombre: 'Estadísticas', imagen: Estadisticas },
  { nombre: 'Tendencias', imagen: Tendencias },
];

const totalServiciosHoy = servicios.length > 0 ? servicios[0].total_servicios : 0;
const totalServiciosMes = serviciosMes.length > 0 ? serviciosMes[0].total_servicios : 0;

// Calcular sumatorias
const sumatoriaPrecioServicio = (data) => data.reduce((acc, ingreso) => acc + parseFloat(ingreso.precio_servicio), 0);
const sumatoriaPrecioAdicional = (data) => data.reduce((acc, ingreso) => acc + parseFloat(ingreso.servicio_adicional_precio), 0);
const sumatoriaTotal = (data) => data.reduce((acc, ingreso) => acc + parseFloat(ingreso.total), 0);

  return (
    <div className="informes-container">
      {contenido === '' && botones.map((boton, index) => (
        <div key={index} className="tarjeta" onClick={boton.onClick}>
          <img src={boton.imagen} alt={boton.nombre} className="tarjeta-imagen" />
          <div className="tarjeta-nombre">{boton.nombre}</div>
        </div>
      ))}
      {contenido === 'servicios' && (
        <div className="servicios-hoy-container">
          <button className="cerrar" onClick={handleCerrar}><img src={regresar} alt="" className="icono" /></button>
          <div className="servicios-hoy">
            <h2>Servicios de Hoy</h2>
            <table>
              <thead>
                <tr>
                  <th>Tipo de Vehículo</th>
                  <th>Cantidad de Servicios</th>
                </tr>
              </thead>
              <tbody>
                {servicios.map((servicio, index) => (
                  <tr key={index}>
                    <td>{servicio.tipo_vehiculo}</td>
                    <td>{servicio.cantidad_servicios}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <table className="total-servicios">
              <thead>
                <tr>
                  <th>Total de Servicios</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{totalServiciosHoy}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="servicios-mes">
            <h2>Servicios del Mes</h2>
            <table>
              <thead>
                <tr>
                  <th>Tipo de Vehículo</th>
                  <th>Cantidad de Servicios</th>
                </tr>
              </thead>
              <tbody>
                {serviciosMes.map((servicio, index) => (
                  <tr key={index}>
                    <td>{servicio.tipo_vehiculo}</td>
                    <td>{servicio.cantidad_servicios}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <table className="total-servicios">
              <thead>
                <tr>
                  <th>Total de Servicios</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{totalServiciosMes}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
      {contenido === 'clientesRecurrentes' && (
        <div className="clientes-recurrentes">
          <button className="cerrar" onClick={handleCerrar}><img src={regresar} alt="" className="icono" /></button>
          <h2>Clientes Recurrentes del Mes</h2>
          <table>
            <thead>
              <tr>
                <th>Nombre del Cliente</th>
                <th>Tipo de Vehículo</th>
                <th>Placa</th>
                <th>Total de Servicios</th>
              </tr>
            </thead>
            <tbody>
              {clientesRecurrentes.map((cliente, index) => (
                <tr key={index}>
                  <td>{cliente.nombre_cliente}</td>
                  <td>{cliente.tipo_vehiculo}</td>
                  <td>{cliente.placa}</td>
                  <td>{cliente.total_servicios}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {contenido === 'ingresosGenerados' && (
        <div className="ingresos-generados">
          <button className="cerrar" onClick={handleCerrar}><img src={regresar} alt="" className="icono" /></button>
          <h2>Ingresos Generados</h2>
          <table>
            <thead>
              <tr>
                <th>Nombre del Servicio</th>
                <th>Precio del Servicio</th>
                <th>Nombre del Servicio Adicional</th>
                <th>Precio del Servicio Adicional</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {ingresosGenerados.map((ingreso, index) => (
                <tr key={index}>
                  <td>{ingreso.nombre_servicio}</td>
                  <td>${parseFloat(ingreso.precio_servicio).toLocaleString('es-ES')}</td>
                  <td>{ingreso.servicio_adicional_nombre}</td>
                  <td>${parseFloat(ingreso.servicio_adicional_precio).toLocaleString('es-ES')}</td>
                  <td>${parseFloat(ingreso.total).toLocaleString('es-ES')}</td>
                </tr>
              ))}
              <tr style={{ backgroundColor: '#f0f0f0', fontWeight: 'bold' }}>
                <td>Total</td>
                <td>${sumatoriaPrecioServicio(ingresosGenerados).toLocaleString('es-ES')}</td>
                <td></td>
                <td>${sumatoriaPrecioAdicional(ingresosGenerados).toLocaleString('es-ES')}</td>
                <td>${sumatoriaTotal(ingresosGenerados).toLocaleString('es-ES')}</td>
              </tr>
            </tbody>
          </table>

          <h2>Ingresos Generados de la Semana</h2>
          <table>
            <thead>
              <tr>
                <th>Nombre del Servicio</th>
                <th>Precio del Servicio</th>
                <th>Nombre del Servicio Adicional</th>
                <th>Precio del Servicio Adicional</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {ingresosGeneradosSemana.map((ingreso, index) => (
                <tr key={index}>
                  <td>{ingreso.nombre_servicio}</td>
                  <td>${parseFloat(ingreso.precio_servicio).toLocaleString('es-ES')}</td>
                  <td>{ingreso.servicio_adicional_nombre}</td>
                  <td>${parseFloat(ingreso.servicio_adicional_precio).toLocaleString('es-ES')}</td>
                  <td>${parseFloat(ingreso.total).toLocaleString('es-ES')}</td>
                </tr>
              ))}
              <tr style={{ backgroundColor: '#f0f0f0', fontWeight: 'bold' }}>
                <td>Total</td>
                <td>${sumatoriaPrecioServicio(ingresosGeneradosSemana).toLocaleString('es-ES')}</td>
                <td></td>
                <td>${sumatoriaPrecioAdicional(ingresosGeneradosSemana).toLocaleString('es-ES')}</td>
                <td>${sumatoriaTotal(ingresosGeneradosSemana).toLocaleString('es-ES')}</td>
              </tr>
            </tbody>
          </table>

          <h2>Ingresos Generados del Mes</h2>
          <table>
            <thead>
              <tr>
                <th>Nombre del Servicio</th>
                <th>Precio del Servicio</th>
                <th>Nombre del Servicio Adicional</th>
                <th>Precio del Servicio Adicional</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {ingresosGeneradosMes.map((ingreso, index) => (
                <tr key={index}>
                  <td>{ingreso.nombre_servicio}</td>
                  <td>${parseFloat(ingreso.precio_servicio).toLocaleString('es-ES')}</td>
                  <td>{ingreso.servicio_adicional_nombre}</td>
                  <td>${parseFloat(ingreso.servicio_adicional_precio).toLocaleString('es-ES')}</td>
                  <td>${parseFloat(ingreso.total).toLocaleString('es-ES')}</td>
                </tr>
              ))}
              <tr style={{ backgroundColor: '#f0f0f0', fontWeight: 'bold' }}>
                <td>Total</td>
                <td>${sumatoriaPrecioServicio(ingresosGeneradosMes).toLocaleString('es-ES')}</td>
                <td></td>
                <td>${sumatoriaPrecioAdicional(ingresosGeneradosMes).toLocaleString('es-ES')}</td>
                <td>${sumatoriaTotal(ingresosGeneradosMes).toLocaleString('es-ES')}</td>
              </tr>
            </tbody>
          </table>

          <h2>Ingresos Generados del Trimestre</h2>
          <table>
            <thead>
              <tr>
                <th>Nombre del Servicio</th>
                <th>Precio del Servicio</th>
                <th>Nombre del Servicio Adicional</th>
                <th>Precio del Servicio Adicional</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {ingresosGeneradosTrimestre.map((ingreso, index) => (
                <tr key={index}>
                  <td>{ingreso.nombre_servicio}</td>
                  <td>${parseFloat(ingreso.precio_servicio).toLocaleString('es-ES')}</td>
                  <td>{ingreso.servicio_adicional_nombre}</td>
                  <td>${parseFloat(ingreso.servicio_adicional_precio).toLocaleString('es-ES')}</td>
                  <td>${parseFloat(ingreso.total).toLocaleString('es-ES')}</td>
                </tr>
              ))}
              <tr style={{ backgroundColor: '#f0f0f0', fontWeight: 'bold' }}>
                <td>Total</td>
                <td>${sumatoriaPrecioServicio(ingresosGeneradosTrimestre).toLocaleString('es-ES')}</td>
                <td></td>
                <td>${sumatoriaPrecioAdicional(ingresosGeneradosTrimestre).toLocaleString('es-ES')}</td>
                <td>${sumatoriaTotal(ingresosGeneradosTrimestre).toLocaleString('es-ES')}</td>
              </tr>
            </tbody>
          </table>

          <h2>Ingresos Generados del Semestre</h2>
          <table>
            <thead>
              <tr>
                <th>Nombre del Servicio</th>
                <th>Precio del Servicio</th>
                <th>Nombre del Servicio Adicional</th>
                <th>Precio del Servicio Adicional</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {ingresosGeneradosSemestre.map((ingreso, index) => (
                <tr key={index}>
                  <td>{ingreso.nombre_servicio}</td>
                  <td>${parseFloat(ingreso.precio_servicio).toLocaleString('es-ES')}</td>
                  <td>{ingreso.servicio_adicional_nombre}</td>
                  <td>${parseFloat(ingreso.servicio_adicional_precio).toLocaleString('es-ES')}</td>
                  <td>${parseFloat(ingreso.total).toLocaleString('es-ES')}</td>
                </tr>
              ))}
              <tr style={{ backgroundColor: '#f0f0f0', fontWeight: 'bold' }}>
                <td>Total</td>
                <td>${sumatoriaPrecioServicio(ingresosGeneradosSemestre).toLocaleString('es-ES')}</td>
                <td></td>
                <td>${sumatoriaPrecioAdicional(ingresosGeneradosSemestre).toLocaleString('es-ES')}</td>
                <td>${sumatoriaTotal(ingresosGeneradosSemestre).toLocaleString('es-ES')}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Informes;