import React, { useState } from 'react';
import swal from 'sweetalert';
import '../estilos/FormularioUsuario.css';
import axios from 'axios';

const FormularioUsuario = ({ cerrarFormulario }) => {
  const [nombre, setNombre] = useState('');
  const [usuario, setUsuario] = useState('');
  const [contraseña, setContraseña] = useState('');
  const [rol, setRol] = useState('');


  // Establecer baseURL de Axios usando la variable de entorno
axios.defaults.baseURL = process.env.REACT_APP_API_URL || "/api";

const handleSubmit = async (e) => {
  e.preventDefault();

  const nuevoUsuario = {
    nombre,
    usuario,
    contraseña,
    rol,
  };

  try {
    // Realizar la petición POST usando axios
    const response = await axios.post("/register", nuevoUsuario);

    if (response.data.success) {
      swal({
        title: "Usuario creado exitosamente",
        icon: "success",
        timer: 2000,
      });
      limpiarFormulario();
      cerrarFormulario();
    } else {
      swal({
        title: "Error al crear el usuario",
        text: response.data.message,
        icon: "error",
        timer: 2000,
      });
    }
  } catch (error) {
    console.error("Error:", error);
    swal({
      title: "Error al conectar con el servidor",
      icon: "error",
      timer: 2000,
    });
  }
};

  const limpiarFormulario = () => {
    setNombre('');
    setUsuario('');
    setContraseña('');
    setRol('');
  };

  return (
    <div className="formulario-usuario">
      <h2>Crear Usuario</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="nombre">Nombre:</label>
          <input
            type="text"
            id="nombre"
            name="nombre"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="usuario">Usuario:</label>
          <input
            type="text"
            id="usuario"
            name="usuario"
            value={usuario}
            onChange={(e) => setUsuario(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="contraseña">Contraseña:</label>
          <input
            type="password"
            id="contraseña"
            name="contraseña"
            value={contraseña}
            onChange={(e) => setContraseña(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="rol">Rol:</label>
          <select
            id="rol"
            name="rol"
            value={rol}
            onChange={(e) => setRol(e.target.value)}
            required
          >
            <option value="admin">Administrador</option>
            <option value="operador">Operador</option>
          </select>
        </div>
        <div className="form-group">
          <button type="submit">Guardar</button>
        </div>
      </form>
    </div>
  );
};

export default FormularioUsuario;