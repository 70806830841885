import React, { useState, useEffect } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import '../estilos/ListaLavadores.css';
import agregarIcono from '../recursos/agregar.png';
import filtrar from '../recursos/Filtrar.png';
import FormularioLavador from './FormularioLavador';

const ListaLavadores = ({ cerrarFormulario }) => {
  const [filtro, setFiltro] = useState('');
  const [lavadores, setLavadores] = useState([]);
  const [mostrandoFormulario, setMostrandoFormulario] = useState(false);
  const [editando, setEditando] = useState(false);
  const [lavadorActual, setLavadorActual] = useState({ id: '', nombre: '', cedula: '', telefono: '' });

  // Establecer baseURL de Axios usando la variable de entorno
  axios.defaults.baseURL = process.env.REACT_APP_API_URL || "/api";

// Obtener lavadores al cargar el componente
    useEffect(() => {
      axios.get('/lavadores')
        .then(response => {
          if (response.data.success) {
            setLavadores(response.data.data);
          } else {
            console.error('Error al obtener la lista de lavadores:', response.data);
          }
        })
        .catch(error => {
          console.error('Error al obtener la lista de lavadores:', error);
        });
    }, []);
  
    const manejarBusqueda = (e) => {
      setFiltro(e.target.value);
    };
  
    const lavadoresFiltrados = lavadores.filter(lavador =>
      lavador.nombre.toLowerCase().includes(filtro.toLowerCase())
    );
  
    const eliminarLavador = (id) => {
      swal({
        title: "¿Estás seguro?",
        text: "Una vez eliminado, no podrás recuperar este lavador.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          axios.delete(`/lavadores/${id}`)
            .then(response => {
              if (response.data.success) {
                setLavadores(lavadores.filter(lavador => lavador.id !== id));
                swal('Lavador eliminado correctamente', '', 'success');
              } else {
                swal('Error al eliminar el lavador', '', 'error');
              }
            })
            .catch(error => {
              console.error('Error al eliminar el lavador:', error);
              swal('Error al eliminar el lavador', '', 'error');
            });
        }
      });
    };
  
    const iniciarEdicion = (lavador) => {
      setLavadorActual(lavador);
      setEditando(true);
      setMostrandoFormulario(true);
    };
  
    const cerrarFormularioLavador = () => {
      setMostrandoFormulario(false);
    };

  return (
    <div className="lista-lavadores">
      {mostrandoFormulario ? (
        <FormularioLavador
          cerrarFormulario={cerrarFormularioLavador}
          lavadorActual={lavadorActual}
          editando={editando}
        />
      ) : (
        <>
          <h2>Lista de Lavadores</h2>
          <div className='acciones-clientesL'>
            <button className="boton-agregar" onClick={() => setMostrandoFormulario(true)}>
              <img src={agregarIcono} alt="Agregar" className="icono-agregar" />
              Agregar Lavador
            </button>
            <div className="barra-busquedaL">
              <input type="text" placeholder="Buscar nombre o placa" value={filtro}
                onChange={manejarBusqueda} className="input-filtroL" />
              <img src={filtrar} alt="Filtrar" className="icono-filtrarL" />
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Cédula</th>
                <th>Teléfono</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {lavadoresFiltrados.map(lavador => (
                <tr key={lavador.id}>
                  <td>{lavador.nombre}</td>
                  <td>{lavador.cedula}</td>
                  <td>{lavador.telefono}</td>
                  <td>
                    <button className="editar" onClick={() => iniciarEdicion(lavador)}>Editar</button>
                    <button className="eliminar" onClick={() => eliminarLavador(lavador.id)}>Eliminar</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default ListaLavadores;