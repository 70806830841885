import React, { useState } from 'react';
import swal from 'sweetalert';
import '../estilos/FormularioCliente.css';

function FormularioCliente({ cerrarFormulario, actualizarClientes }) {
  // Estados para los datos del cliente
  const [nombre, setNombre] = useState('');
  const [direccion, setDireccion] = useState('');
  const [telefono, setTelefono] = useState('');
  const [correo, setCorreo] = useState('');
  const [nota, setNota] = useState('');

  // Estados para los datos del vehículo
  const [tipo, setTipo] = useState('');
  const [placa, setPlaca] = useState('');
  const [modelo, setModelo] = useState('');
  const [color, setColor] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const nuevoCliente = {
      nombre,
      direccion,
      telefono,
      correo,
      nota,
      vehiculo: {
        tipo,
        placa,
        modelo,
        color,
      },
    };
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/clientes/store`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(nuevoCliente),
      });
  
      if (response.ok) {
        swal({
          title: "Cliente y vehículo creados exitosamente",
          icon: "success",
          timer: 2000,
          buttons: false,
          className: 'swal-center'
        });
        limpiarFormulario();
        cerrarFormulario();
        actualizarClientes(); // Actualizar la lista de clientes
      } else {
        swal({
          title: "Error al crear el cliente y vehículo",
          icon: "error",
          timer: 2000,
          buttons: false,
          className: 'swal-center'
        });
      }
    } catch (error) {
      swal({
        title: "Error al conectar con el servidor",
        icon: "error",
        timer: 2000,
        buttons: false,
        className: 'swal-center'
      });
    }
  };
  

  const limpiarFormulario = () => {
    setNombre('');
    setDireccion('');
    setTelefono('');
    setCorreo('');
    setNota('');
    setTipo('');
    setPlaca('');
    setModelo('');
    setColor('');
  };

  return (
    <div className="formulario-clientes">
      <button className="cerrar-formulario" onClick={cerrarFormulario}>
        X
      </button>
      <form onSubmit={handleSubmit}>
        <div className="formulario-columnas">
          <div className="columna">
            <h3>Datos del Cliente</h3>
            <div>
              <label>Nombre:</label>
              <input
                type="text"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Dirección:</label>
              <input
                type="text"
                value={direccion}
                onChange={(e) => setDireccion(e.target.value)}
              />
            </div>
            <div>
              <label>Teléfono:</label>
              <input
                type="text"
                value={telefono}
                onChange={(e) => setTelefono(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Correo:</label>
              <input
                type="text"
                value={correo}
                onChange={(e) => setCorreo(e.target.value)}
              />
            </div>
            <div>
              <label>Nota:</label>
              <textarea
                value={nota}
                onChange={(e) => setNota(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div className="columna">
            <h3>Datos del Vehículo</h3>
            <div>
              <label>Tipo Vehículo:</label>
              <select
                value={tipo}
                onChange={(e) => setTipo(e.target.value)}
                required
              >
                <option value="" disabled>
                  Selecciona un tipo
                </option>
                <option>Automóvil</option>
                <option>Bus</option>
                <option>Buseta</option>
                <option>Camión</option>
                <option>Camioneta</option>
                <option>Campero</option>
                <option>Microbús</option>
                <option>Tractocamión</option>
                <option>Motocicleta</option>
                <option>Motocarro</option>
                <option>Mototriciclo</option>
                <option>Cuatrimoto</option>
                <option>Volqueta</option>
              </select>
            </div>
            <div>
              <label>Placa:</label>
              <input
                type="text"
                value={placa}
                onChange={(e) => setPlaca(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Modelo:</label>
              <input
                type="text"
                value={modelo}
                onChange={(e) => setModelo(e.target.value)}
              />
            </div>
            <div>
              <label>Color:</label>
              <input
                type="text"
                value={color}
                onChange={(e) => setColor(e.target.value)}
                required
              />
            </div>
            <div className="formulario-botones">
          <button className='btnFormulario' type="submit">Guardar</button>
          <button className='btnFormulario' type="button" onClick={limpiarFormulario}>
            Limpiar
          </button>
        </div>
          </div>
        </div>
        
      </form>
    </div>
  );
}

export default FormularioCliente;