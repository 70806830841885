import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from 'sweetalert';
import "../estilos/FormularioServicio.css";
import echo from '../echo'; // Importar la configuración de Laravel Echo

const FormularioServicio = ({ cerrarFormulario }) => {
  const [placa, setPlaca] = useState("");
  const [placasSugeridas, setPlacasSugeridas] = useState([]);
  const [vehiculo, setVehiculo] = useState(null);
  const [cliente, setCliente] = useState(null);
  const [clienteId, setClienteId] = useState("");
  const [vehiculoId, setVehiculoId] = useState("");
  const [productosServicios, setProductosServicios] = useState([]);
  const [servicioSeleccionado, setServicioSeleccionado] = useState(null);
  const [servicioAdicionalNombre, setServicioAdicionalNombre] = useState("");
  const [servicioAdicionalPrecio, setServicioAdicionalPrecio] = useState(0);
  const [total, setTotal] = useState(0);
  const [estado, setEstado] = useState("en proceso"); // Estado para el campo "estado"
  const [lavadores, setLavadores] = useState([]); // Estado para almacenar la lista de lavadores
  const [lavadorSeleccionado, setLavadorSeleccionado] = useState(null); // Estado para el lavador seleccionado

  // Establecer baseURL de Axios usando una variable de entorno
axios.defaults.baseURL = process.env.REACT_APP_API_URL || "/api"; 

useEffect(() => {
  axios
    .get("/productos-servicios")
    .then((response) => {
      if (response.data.success && Array.isArray(response.data.data)) {
        setProductosServicios(response.data.data);
      } else {
        console.error("La API no devolvió un array válido:", response.data);
        setProductosServicios([]);
      }
    })
    .catch((error) => {
      console.error("Error al cargar los servicios:", error);
      setProductosServicios([]);
    });

  // Obtener la lista de lavadores
  axios
    .get("/lavadores")
    .then((response) => {
      if (response.data.success && Array.isArray(response.data.data)) {
        setLavadores(response.data.data);
      } else {
        console.error("La API no devolvió un array válido:", response.data);
        setLavadores([]);
      }
    })
    .catch((error) => {
      console.error("Error al cargar los lavadores:", error);
      setLavadores([]);
    });
}, []);

// Función de búsqueda de placas con debounce
const buscarPlacas = (texto) => {
  if (texto.length >= 1) {
    axios
      .get(`/clientes/buscar-placa?placa=${texto}`)
      .then((response) => {
        if (response.data.success) {
          setPlacasSugeridas(response.data.data);
        } else {
          setPlacasSugeridas([]); 
        }
      })
      .catch(() => setPlacasSugeridas([]));
  } else {
    setPlacasSugeridas([]); 
  }
};

let debounceTimeout = null;

const manejarCambioPlaca = (e) => {
  const texto = e.target.value;
  setPlaca(texto);

  if (debounceTimeout) clearTimeout(debounceTimeout);
  debounceTimeout = setTimeout(() => buscarPlacas(texto), 300);
};

const seleccionarPlaca = (placaSeleccionada) => {
  setPlaca(placaSeleccionada);
  setPlacasSugeridas([]);

  axios
    .get(`/clientes/buscar-placa?placa=${placaSeleccionada}`)
    .then((response) => {
      if (response.data.success && response.data.data.length > 0) {
        const data = response.data.data[0];
        setVehiculo(data.vehiculo);
        setCliente(data.cliente);
        setClienteId(data.cliente.id);
        setVehiculoId(data.vehiculo.id);
      } else {
        setVehiculo(null);
        setCliente(null);
        setClienteId("");
        setVehiculoId("");
      }
    })
    .catch(() => {
      setVehiculo(null);
      setCliente(null);
      setClienteId("");
      setVehiculoId("");
    });
};

const manejarSeleccionServicio = (idServicio) => {
  const productoservicio = productosServicios.find((s) => s.id === parseInt(idServicio));
  setServicioSeleccionado(productoservicio);
  calcularTotal(productoservicio?.precio, servicioAdicionalPrecio);
};

const calcularTotal = (precioServicio = 0, precioAdicional = 0) => {
  const total = parseFloat(precioServicio) + parseFloat(precioAdicional);
  setTotal(isNaN(total) ? 0 : total);
};

const manejarEnvio = (e) => {
  e.preventDefault();
  if (!servicioSeleccionado) {
    swal({
      title: "Error",
      text: "Debe seleccionar un servicio",
      icon: "error",
      timer: 2000
    });
    return;
  }

  if (!lavadorSeleccionado) {
    swal({
      title: "Error",
      text: "Debe seleccionar un lavador",
      icon: "error",
      timer: 2000
    });
    return;
  }

  const datos = {
    cliente_id: clienteId,
    vehiculo_id: vehiculoId,
    producto_servicio_id: servicioSeleccionado.id,
    producto_servicio_nombre: servicioSeleccionado.nombre,
    producto_servicio_precio: servicioSeleccionado.precio,
    precio_servicio: servicioSeleccionado.precio,
    servicio_adicional_nombre: servicioAdicionalNombre,
    servicio_adicional_precio: parseFloat(servicioAdicionalPrecio),
    estado: estado,
    total: total,
    lavador_id: lavadorSeleccionado.id,
    lavador_nombre: lavadorSeleccionado.nombre
  };

  axios
    .post("/servicios", datos)
    .then((response) => {
      swal({
        title: "Servicio creado correctamente",
        icon: "success",
        timer: 2000
      });
      echo.channel('servicios').listen('ServicioActualizado', { servicio: response.data });
      limpiarFormulario();
      cerrarFormularioLocal(); 
    })
    .catch((error) => {
      const mensajeError = error.response?.data ? JSON.stringify(error.response.data) : error.message;
      swal({
        title: "Error al crear el servicio",
        text: mensajeError,
        icon: "error",
        timer: 5000
      });
    });
};

  const limpiarFormulario = () => {
    setPlaca("");
    setPlacasSugeridas([]);
    setVehiculo(null);
    setCliente(null);
    setClienteId("");
    setVehiculoId("");
    setServicioSeleccionado(null);
    setServicioAdicionalNombre("");
    setServicioAdicionalPrecio(0);
    setTotal(0);
    setEstado("en proceso");
    setLavadorSeleccionado(null);
  };

  const cerrarFormularioLocal = () => {
    limpiarFormulario();
    cerrarFormulario();
  };

  return (
    <div className="formulario-servicio">
      <div className="formulario-header">
        <h3>Datos del Servicio</h3>
      </div>
      <form onSubmit={manejarEnvio}>
        <div className="formulario-columnas">
          <div className="columna">
            <div>
              <label>Placa</label>
              <input
                type="text"
                value={placa}
                onChange={manejarCambioPlaca}
                placeholder="Escribe la placa"
              />
              {placasSugeridas.length > 0 && (
                <ul className="sugerencias-placas">
                  {placasSugeridas.map((p, index) => (
                    <li key={index} onClick={() => seleccionarPlaca(p.vehiculo.placa)}>
                      {p.vehiculo.placa}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            {cliente && (
              <>
                <div>
                  <label>Nombre del Cliente</label>
                  <input type="text" value={cliente.nombre} readOnly />
                </div>
                <div>
                  <label>Teléfono del Cliente</label>
                  <input type="text" value={cliente.telefono} readOnly />
                </div>
                <div>
                  <label>Correo del Cliente</label>
                  <input type="text" value={cliente.correo} readOnly />
                </div>
                <div>
                  <button className='btnFormulario' type="submit">Crear Servicio</button>
                </div>
              </>
            )}
          </div>
          <div className="columna">
            {vehiculo && (
              <>
                <div>
                  <label>Tipo de Vehículo</label>
                  <input type="text" value={vehiculo.tipo_vehiculo} readOnly />
                </div>
                <div>
                  <label>Color</label>
                  <input type="text" value={vehiculo.color} readOnly />
                </div>
              </>
            )}
            <div>
              <label>Tipo de Servicio</label>
              <select onChange={(e) => manejarSeleccionServicio(e.target.value)}>
                <option value="">Seleccione un servicio</option>
                {productosServicios.map((s) => (
                  <option key={s.id} value={s.id}>
                    {s.nombre} - ${s.precio}
                  </option>
                ))}
              </select>
            </div>
            {servicioSeleccionado && (
              <div>
                <label>Precio</label>
                <input type="number" value={servicioSeleccionado.precio} readOnly />
              </div>
            )}
            <div>
              <label>Total</label>
              <input className="inputotal" type="number" value={total} readOnly />
            </div>
          </div>
          <div className="columna">
            <div>
              <label>Servicio Adicional</label>
              <input
                type="text"
                value={servicioAdicionalNombre}
                onChange={(e) => setServicioAdicionalNombre(e.target.value)}
                placeholder="Nombre del servicio adicional"
              />
            </div>
            <div>
              <label>Precio Adicional</label>
              <input
                type="number"
                value={servicioAdicionalPrecio}
                onFocus={(e) => e.target.value === "0" && setServicioAdicionalPrecio("")}
                onChange={(e) => {
                  const precio = parseFloat(e.target.value);
                  setServicioAdicionalPrecio(isNaN(precio) ? 0 : precio);
                  calcularTotal(servicioSeleccionado?.precio, precio);
                }}
              />
            </div>
            <div>
              <label>Estado</label>
              <select value={estado} onChange={(e) => setEstado(e.target.value)}>
                <option value="en proceso">En Proceso</option>
                <option value="terminado">Terminado</option>
              </select>
            </div>
            <div>
              <label>Lavador</label>
              <select onChange={(e) => {
                const lavador = lavadores.find(l => l.id === parseInt(e.target.value));
                setLavadorSeleccionado(lavador);
              }}>
                <option value="">Seleccione un lavador</option>
                {lavadores.map((l) => (
                  <option key={l.id} value={l.id}>
                    {l.nombre}
                  </option>
                ))}
              </select>
            </div>

          </div>
        </div>
      </form>
    </div>
  );
};

export default FormularioServicio;