import React, { useState, useEffect, useRef } from "react";
import "../estilos/Encabezado.css";
import logo from "../recursos/logoTecno.png";
import configIcon from "../recursos/configuracion.png";
import Ajustes from "./Ajustes";

function Encabezado({ usuarioInfo }) {
  const [mostrarAjustes, setMostrarAjustes] = useState(false);
  const ajustesRef = useRef(null);

  const handleConfigClick = () => {
    setMostrarAjustes(!mostrarAjustes);
  };

  const handleClickOutside = (event) => {
    if (ajustesRef.current && !ajustesRef.current.contains(event.target)) {
      setMostrarAjustes(false);
    }
  };

  useEffect(() => {
    if (mostrarAjustes) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [mostrarAjustes]);

  return (
    <header className="encabezado">
      <div className="icono-config">
        <img src={logo} alt="Logo de la Aplicación" className="logo-img" />
      </div>
      <div className="nombre-aplicacion">TecnoLavado</div>
      <div className="logo">
        <button onClick={handleConfigClick}>
          <img src={configIcon} alt="Configuración" className="config-icon" />
        </button>
        {mostrarAjustes && (
          <div ref={ajustesRef} className="ajustes-popup">
            <Ajustes usuarioInfo={usuarioInfo} />
          </div>
        )}
      </div>
    </header>
  );
}

export default Encabezado;