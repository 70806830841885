import React from 'react';
import '../estilos/PiePagina.css'; 

const PiePagina = () => {
    return (
        <footer className="footer">
            <div className="parrafos columnWithBorder">
                <div className='div-parrafo'><p className="parrafo-copyright">© 2024 Diseñado y Desarrollado por TecnoMinds</p></div>
            </div>
        </footer>
    );
};

export default PiePagina;