import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import FormularioCliente from './FormularioCliente';
import FormularioProductoServicio from './FormularioProductoServicio';
import FormularioServicio from './FormularioServicio';
import icono from '../recursos/agregar.png';
import '../estilos/Pantalla.css';
import Servicios from './Servicios';
import filtrar from '../recursos/Filtrar.png';
import Informes from './Informes';
import ListaLavadores from './ListaLavadores';
import FormularioUsuario from './FormularioUsuarios';

function Pantalla({ formularioActual, usuarioInfo }) {
  const [clientes, setClientes] = useState([]);
  const [productosServicios, setProductosServicios] = useState([]);
  const [mostrarFormularioCliente, setMostrarFormularioCliente] = useState(false);
  const [mostrarFormularioProductoServicio, setMostrarFormularioProductoServicio] = useState(false);
  const [mostrarFormularioServicio, setMostrarFormularioServicio] = useState(false);
  const [filtro, setFiltro] = useState('');
  const [mostrarInformes, setMostrarInformes] = useState(false);
  const [MostrarLavadores, setMostrarLavadores] = useState('');
  const [mostrarFormularioUsuario, setMostrarFormularioUsuario] = useState(false);
  
  useEffect(() => {
    if (formularioActual === 'Clientes') {
      fetchClientes();
    } else if (formularioActual === 'Productos y Servicios') {
      fetchProductosServicios();
    } 
    else if (formularioActual === 'Crear Usuarios') {
      setMostrarFormularioUsuario(true);
    }
    else if (formularioActual === 'Informes') {
    }
    else if (formularioActual === 'Lavadores') {
    }
  }, [formularioActual]);
  
  const fetchClientes = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL || "/api"; // Uso de la URL desde el archivo .env
      const response = await fetch(`${apiUrl}/clientes/index`); 
      if (response.ok) {
        const data = await response.json();
        setClientes(data.data); 
      } else {
        console.error('Error en la respuesta del servidor');
      }
    } catch (error) {
      console.error('Error al conectar con el servidor:', error);
    }
  };
  
  const fetchProductosServicios = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL || "/api"; // Uso de la URL desde el archivo .env
      const response = await fetch(`${apiUrl}/productos-servicios`); 
      if (response.ok) {
        const data = await response.json();
        setProductosServicios(data.data); 
      } else {
        console.error('Error en la respuesta del servidor');
      }
    } catch (error) {
      console.error('Error al conectar con el servidor:', error);
    }
  };
  
  const toggleFormularioCliente = () => {
    setMostrarFormularioCliente(!mostrarFormularioCliente);
  };
  
  const toggleFormularioProductoServicio = () => {
    setMostrarFormularioProductoServicio(!mostrarFormularioProductoServicio);
  };
  
  const toggleFormularioServicio = () => {
    setMostrarFormularioServicio(!mostrarFormularioServicio);
  };
  
  const toggleInformes = () => {
    setMostrarInformes(!mostrarInformes);
  };
  
  const toggleLavadores = () => {
    setMostrarLavadores(MostrarLavadores === 'Lavadores' ? '' : 'Lavadores');
  };
  
  const toggleFormularioUsuario = () => {
    setMostrarFormularioUsuario(!mostrarFormularioUsuario);
  };
  
  const mostrarMensaje = (titulo, icono) => {
    swal({
      title: titulo,
      icon: icono,
      timer: 2000,
      buttons: false,
      className: 'swal-center'
    });
  };
  
  // Función para eliminar un producto o servicio
  const eliminarProductoServicio = async (id) => {
    swal({
      title: "¿Estás seguro?",
      text: "Una vez eliminado, no podrás recuperar este producto o servicio.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const apiUrl = process.env.REACT_APP_API_URL || "/api"; // Uso de la URL desde el archivo .env
        fetch(`${apiUrl}/productos-servicios/${id}`, {
          method: 'DELETE',
        }).then((response) => {
          if (response.ok) {
            setProductosServicios(productosServicios.filter((item) => item.id !== id));
            mostrarMensaje("Producto o servicio eliminado", "success");
          } else {
            mostrarMensaje("Error al eliminar producto o servicio", "error");
          }
        }).catch((error) => {
          mostrarMensaje("Error al conectar con el servidor", "error");
        });
      }
    });
  };
  
  // Función para actualizar un producto o servicio
  const actualizarProductoServicio = async (id, actualizado) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL || "/api"; // Uso de la URL desde el archivo .env
      const response = await fetch(`${apiUrl}/productos-servicios/${id}`, { 
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(actualizado),
      });
      if (response.ok) {
        fetchProductosServicios(); // Actualizar la lista
        mostrarMensaje("Producto o servicio actualizado", "success");
      } else {
        mostrarMensaje("Error al actualizar producto o servicio", "error");
      }
    } catch (error) {
      mostrarMensaje("Error al conectar con el servidor", "error");
    }
  };
  
  const actualizarClientes = () => {
    fetchClientes();
  };
  
  const actualizarProductosServicios = () => {
    fetchProductosServicios();
  };
  
  const clientesFiltrados = clientes.filter(cliente => 
    cliente.nombre.toLowerCase().includes(filtro.toLowerCase()) ||
    (cliente.vehiculos && cliente.vehiculos.some(vehiculo => vehiculo.placa.toLowerCase().includes(filtro.toLowerCase())))
  );

  if (formularioActual === 'Clientes') {
    return (
      <div className="pantalla-contenido">
        {mostrarFormularioCliente ? (
          <FormularioCliente cerrarFormulario={toggleFormularioCliente} actualizarClientes={actualizarClientes} />
        ) : (
          <div className="lista-clientes">
            <div className="acciones-clientes">
              <button className="boton-crear-cliente" onClick={toggleFormularioCliente}>
                <img className="icono-crear" src={icono} alt="" /> Crear Cliente
              </button>
              <div className="barra-busqueda">
              <input type="text" placeholder="Buscar nombre o placa" value={filtro}
                onChange={(e) => setFiltro(e.target.value)} className="input-filtro"/>
              <img src={filtrar} alt="Filtrar" className="icono-filtrar" />
                </div>
            </div>
            <h2>Lista de Clientes</h2>
            {clientesFiltrados.length > 0 ? (
              <ul>
                {clientesFiltrados.map((cliente) => (
                  <li key={cliente.id}>
                    <div className="columna nombre">
                      <p>{cliente.nombre}</p>
                      <p><strong>Dirección:</strong> {cliente.direccion || 'No disponible'}</p>
                      <p><strong>Teléfono:</strong> {cliente.telefono || 'No disponible'}</p>
                    </div>
                    <div className="columna correo-nota">
                      <p><strong>Correo:</strong> {cliente.correo || 'No disponible'}</p>
                      <p><strong>Nota:</strong> {cliente.nota || 'No disponible'}</p>
                    </div>
                    <div className="columna vehiculo">
                      {cliente.vehiculos && cliente.vehiculos.length > 0 && (
                        <div className="vehiculos">
                          <h3>Vehículos:</h3>
                          <ul>
                            {cliente.vehiculos.map((vehiculo) => (
                              <li key={vehiculo.id}>
                                <p><strong>Tipo:</strong> {vehiculo.tipo_vehiculo}</p>
                                <p><strong>Placa:</strong> {vehiculo.placa}</p>
                                <p><strong>Modelo:</strong> {vehiculo.modelo}</p>
                                <p><strong>Color:</strong> {vehiculo.color}</p>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No hay clientes registrados</p>
            )}
          </div>
        )}
      </div>
    );
  }

  if (formularioActual === 'Productos y Servicios') {
    return (
      <div className="pantalla-contenido">
        {mostrarFormularioProductoServicio ? (
          <FormularioProductoServicio cerrarFormulario={toggleFormularioProductoServicio} actualizarProductosServicios={actualizarProductosServicios} />
        ) : (
          <div className="lista-productos-servicios">
          <button className="boton-crear-cliente" onClick={toggleFormularioProductoServicio}>
            <img className="icono-crear" src={icono} alt="Agregar" /> Agregar Servicio o Producto
          </button>
          <h2>Lista de Productos y Servicios</h2>
          {productosServicios.length > 0 ? (
            <ul>
              {productosServicios.map((item) => (
                <li key={item.id}>
                  <div className="producto-nombre">
                    <p>{item.nombre}</p>
                  </div>
                  <div className="producto-descripcion">
                    <p><strong>Descripción:</strong> {item.descripcion}</p>
                  </div>
                  <div className="producto-precio">
                    <p><strong>Precio:</strong> ${item.precio}</p>
                  </div>
                  <div className="producto-acciones">
                    <button className='btnFormularioProductos'
                      onClick={() =>
                        actualizarProductoServicio(item.id, {
                          nombre: prompt('Nuevo nombre:', item.nombre),
                          descripcion: prompt('Nueva descripción:', item.descripcion),
                          precio: parseFloat(prompt('Nuevo precio:', item.precio)),
                        })
                      }
                    >
                      Editar
                    </button>
                    <button className='btnFormularioProductos' onClick={() => eliminarProductoServicio(item.id)}>Eliminar</button>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p>No hay productos o servicios registrados</p>
          )}
        </div>
        )}
      </div>
    );
  }

  if (formularioActual === 'Nuevo Servicio') {
    return (
      <div className="pantalla-contenido">
        <FormularioServicio cerrarFormulario={toggleFormularioServicio} />
      </div>
    );
  }
  if (formularioActual === 'Informes') {
    return (
      <div className="pantalla-contenido">
        <Informes cerrarFormulario={toggleInformes} usuarioInfo={usuarioInfo} />
      </div>
    );
  }
  if (formularioActual === 'Lavadores') {
    return (
      <div className="pantalla-contenido">
        <ListaLavadores cerrarFormulario={toggleLavadores} />
      </div>
    );
  }
  if (formularioActual === 'Crear Usuarios') {
    return (
      <div className="pantalla-contenido">
        <FormularioUsuario cerrarFormulario={toggleFormularioUsuario} />
      </div>
    );
  }


  return (
    <div className="pantalla-contenido">
      <Servicios/>
      </div>
  );
}

export default Pantalla;