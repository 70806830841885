import React, { useState } from 'react';
import Encabezado from './Encabezado';
import Opciones from './Opciones';
import Pantalla from './Pantalla';
import '../estilos/Admin.css';
import PiePagina from './PiePagina';

const Operador = ({ usuarioInfo }) => {
  const [formularioActual, setFormularioActual] = useState(null);

  const manejarSeleccion = (opcion) => {
    setFormularioActual(opcion);
  };

  return (
    <div className="contenedor-general">
      <Encabezado usuarioInfo={usuarioInfo}/>
      <div className="contenedor-central">
        <Opciones manejarSeleccion={manejarSeleccion} usuarioInfo={usuarioInfo}/>
        <Pantalla formularioActual={formularioActual} />
      </div>
      <PiePagina/>
    </div>
  );
}

export default Operador;
