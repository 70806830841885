import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

// Configuración de Pusher y Echo
window.Pusher = Pusher;

const echo = new Echo({
    broadcaster: 'pusher',
    key: "61beb840468db6bf49b2",   
    cluster: "sa1",  
    encrypted: true
});

export default echo;