import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../estilos/Ajustes.css';

const Ajustes = ({ usuarioInfo }) => {
  const navigate = useNavigate();

  

  const handleLogout = async () => {
    try {
      const response = await fetch('/api/logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        if (data.success) {
          // Redirigir al usuario a la página de inicio de sesión
          sessionStorage.removeItem('nombre');
          sessionStorage.removeItem('rol');
          navigate('/');
        } else {
          console.error('Logout failed:', data.message);
        }
      } else {
        console.error('Logout failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <div className="ajustes-container">
      <h1>{usuarioInfo?.nombre || 'Usuario'}</h1>
      <hr />
      <p>Rol: {usuarioInfo?.rol || 'Desconocido'}</p>
      <button onClick={handleLogout}>
        Cerrar sesión
      </button>
    </div>
  );
};

export default Ajustes;