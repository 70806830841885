import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Admin from './componentes/Admin';
import Login from './componentes/LoginForm';
import Operador from './componentes/Operador';
import './App.css';

const App = () => {
  const [usuarioInfo, setUsuarioInfo] = useState({
    nombre: sessionStorage.getItem('nombre') || '',
    rol: sessionStorage.getItem('rol') || ''
  });

  useEffect(() => {
  }, [usuarioInfo]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login setUsuarioInfo={setUsuarioInfo} />} />
        <Route path="/admin" element={<Admin usuarioInfo={usuarioInfo} />} />
        <Route path="/operador" element={<Operador usuarioInfo={usuarioInfo} />} />
      </Routes>
    </Router>
  );
};

export default App;