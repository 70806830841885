import React, { useState } from 'react';
import '../estilos/LoginForm.css';

const LoginForm = ({ setUsuarioInfo }) => {
  const [usuario, setUsuario] = useState('');
  const [contraseña, setContraseña] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await fetch("https://tecnolavado.com/backend/public/api/login", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ usuario, contrasena: contraseña }), 
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        console.error('Error en el inicio de sesión:', data.message || 'Error desconocido');
        return;
      }
  
      if (data.success) {
        const { nombre, rol } = data.data;
        setUsuarioInfo({ nombre, rol });
        sessionStorage.setItem('nombre', nombre);
        sessionStorage.setItem('rol', rol);
  
        // Redirigir según el rol
        if (rol === 'admin') {
          window.location.href = '/admin';
        } else if (rol === 'operador') {
          window.location.href = '/operador';
        }
      } else {
        console.error('Inicio de sesión fallido:', data.message);
      }
    } catch (error) {
      console.error('Error al conectarse con el servidor:', error);
    }
  };

  return (
    <div className='loginSolo'>
      <form className='formularioSolo' onSubmit={handleSubmit}>
        <div>
          <input
            className='inputSolo'
            type="text"
            placeholder="Usuario"
            value={usuario}
            onChange={(e) => setUsuario(e.target.value)}
            required
          />
        </div>
        <div>
          <input
            className='inputSolo'
            type="password"
            placeholder="Contraseña"
            value={contraseña}
            onChange={(e) => setContraseña(e.target.value)}
            required
          />
        </div>
        <button className='botonSolo' type="submit">Ingresar</button>
      </form>
      <p className='parrafoSolo'>TecnoMinds, el mejor aliado para tu negocio</p>
    </div>
  );
};

export default LoginForm;
