import { useEffect, useState, useCallback } from "react";
import swal from 'sweetalert';
import '../estilos/Servicios.css';
import filtrar from '../recursos/Filtrar.png';

function Todos({ cerrar }) {
  const [servicios, setServicios] = useState([]);
  const [productosServicios, setProductosServicios] = useState([]);
  const [lavadores, setLavadores] = useState([]); // Estado para almacenar la lista de lavadores
  const [filtro, setFiltro] = useState('');
  const [editando, setEditando] = useState(null); // Estado para controlar la edición
  const [formData, setFormData] = useState({}); // Estado para almacenar los datos del formulario

  const API_URL = process.env.REACT_APP_API_URL || "/api";
 
const fetchServicios = useCallback(async () => {
  try {
      const response = await fetch(`${API_URL}/api/servicios/todos`);
      if (response.ok) {
          const data = await response.json();
          setServicios(data);
      } else {
          console.error("Error en la respuesta del servidor");
      }
  } catch (error) {
      console.error("Error al conectar con el servidor:", error);
  }
}, [API_URL]);

const fetchProductosServicios = useCallback(async () => {
  try {
      const response = await fetch(`${API_URL}/api/productos-servicios`);
      if (response.ok) {
          const data = await response.json();
          setProductosServicios(data.data);
      } else {
          console.error("Error en la respuesta del servidor");
      }
  } catch (error) {
      console.error("Error al conectar con el servidor:", error);
  }
}, [API_URL]);

const fetchLavadores = useCallback(async () => {
  try {
      const response = await fetch(`${API_URL}/api/lavadores`);
      if (response.ok) {
          const data = await response.json();
          setLavadores(data.data);
      } else {
          console.error("Error en la respuesta del servidor");
      }
  } catch (error) {
      console.error("Error al conectar con el servidor:", error);
  }
}, [API_URL]);

const actualizarServicio = async (id, actualizado) => {
  try {
      const response = await fetch(`${API_URL}/api/servicios/${id}`, { 
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(actualizado),
      });
      if (response.ok) {
          fetchServicios();
          swal({
              title: "Servicio actualizado correctamente",
              icon: "success",
              timer: 2000
          });
          setEditando(null);
      } else {
          swal({
              title: "Error al actualizar el servicio",
              icon: "error",
              timer: 2000
          });
      }
  } catch (error) {
      swal({
          title: "Error al conectar con el servidor",
          icon: "error",
          timer: 2000
      });
  }
};

const eliminarServicio = async (id) => {
  swal({
      title: "¿Estás seguro?",
      text: "Una vez eliminado, no podrás recuperar este servicio.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
  }).then((willDelete) => {
      if (willDelete) {
          fetch(`${API_URL}/api/servicios/${id}`, { 
              method: 'DELETE',
          }).then((response) => {
              if (response.ok) {
                  setServicios(servicios.filter((servicio) => servicio.id !== id));
                  swal({
                      title: "Servicio eliminado correctamente",
                      icon: "success",
                      timer: 2000
                  });
              } else {
                  swal({
                      title: "Error al eliminar el servicio",
                      icon: "error",
                      timer: 2000
                  });
              }
          }).catch((error) => {
              swal({
                  title: "Error al conectar con el servidor",
                  icon: "error",
                  timer: 2000
              });
          });
      }
  });
};

useEffect(() => {
  fetchServicios();
  fetchProductosServicios();
  fetchLavadores();
}, [fetchServicios, fetchProductosServicios, fetchLavadores]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        [name]: value,
      };
      if (name === 'servicio_adicional_precio') {
        updatedFormData.total = parseFloat(prevFormData.precio_servicio || 0) + parseFloat(value || 0);
      } else if (name === 'precio_servicio') {
        updatedFormData.total = parseFloat(value || 0) + parseFloat(prevFormData.servicio_adicional_precio || 0);
      }
      return updatedFormData;
    });
  };

  const handleServicioChange = (e) => {
    const selectedServicio = productosServicios.find(s => s.id === parseInt(e.target.value));
    setFormData((prevFormData) => {
      const servicioAdicionalPrecio = parseFloat(prevFormData.servicio_adicional_precio || 0);
      const updatedFormData = {
        ...prevFormData,
        nombre_servicio: selectedServicio.nombre,
        precio_servicio: selectedServicio.precio,
        producto_servicio_id: selectedServicio.id, // Agregar el ID del producto/servicio
        total: parseFloat(selectedServicio.precio) + servicioAdicionalPrecio,
      };
      return updatedFormData;
    });
  };

  const handleLavadorChange = (e) => {
    const selectedLavador = lavadores.find(l => l.id === parseInt(e.target.value));
    setFormData((prevFormData) => ({
      ...prevFormData,
      lavador_nombre: selectedLavador.nombre,
      lavador_id: selectedLavador.id, // Agregar el ID del lavador
    }));
  };

  const handleActualizar = (id) => {
    const servicioOriginal = servicios.find(s => s.id === id);
    const actualizado = {};

    // Solo incluir los campos que han sido modificados
    if (formData.nombre_cliente && formData.nombre_cliente !== servicioOriginal.nombre_cliente) {
      actualizado.nombre_cliente = formData.nombre_cliente;
    }
    if (formData.tipo_vehiculo && formData.tipo_vehiculo !== servicioOriginal.tipo_vehiculo) {
      actualizado.tipo_vehiculo = formData.tipo_vehiculo;
    }
    if (formData.precio_servicio && parseFloat(formData.precio_servicio) !== servicioOriginal.precio_servicio) {
      actualizado.precio_servicio = parseFloat(formData.precio_servicio);
    }
    if (formData.servicio_adicional_nombre && formData.servicio_adicional_nombre !== servicioOriginal.servicio_adicional_nombre) {
      actualizado.servicio_adicional_nombre = formData.servicio_adicional_nombre;
    }
    if (formData.servicio_adicional_precio && parseFloat(formData.servicio_adicional_precio) !== servicioOriginal.servicio_adicional_precio) {
      actualizado.servicio_adicional_precio = parseFloat(formData.servicio_adicional_precio);
    }
    if (formData.estado && formData.estado !== servicioOriginal.estado) {
      actualizado.estado = formData.estado;
    }
    if (formData.total && parseFloat(formData.total) !== servicioOriginal.total) {
      actualizado.total = parseFloat(formData.total);
    }
    if (formData.vehiculo_id && formData.vehiculo_id !== servicioOriginal.vehiculo_id) {
      actualizado.vehiculo_id = formData.vehiculo_id;
    }
    if (formData.cliente_id && formData.cliente_id !== servicioOriginal.cliente_id) {
      actualizado.cliente_id = formData.cliente_id;
    }
    if (formData.producto_servicio_id && formData.producto_servicio_id !== servicioOriginal.producto_servicio_id) {
      actualizado.producto_servicio_id = formData.producto_servicio_id;
      actualizado.nombre_servicio = formData.nombre_servicio;
    }
    if (formData.lavador_id && formData.lavador_id !== servicioOriginal.lavador_id) {
      actualizado.lavador_id = formData.lavador_id;
      actualizado.lavador_nombre = formData.lavador_nombre;
    }

    actualizarServicio(id, actualizado);
  };

  const serviciosFiltrados = servicios.filter(servicio => 
    servicio.nombre_cliente.toLowerCase().includes(filtro.toLowerCase()) ||
    servicio.placa.toLowerCase().includes(filtro.toLowerCase())
  );

  return (
    <div className="Lista-servicios">
      <div className="acciones-clientes">
        <div className='barra-busqueda'>
        <input
          type="text" placeholder="Buscar por nombre o placa" value={filtro}
          onChange={(e) => setFiltro(e.target.value)} className="input-filtro"/>
        <img src={filtrar} alt="Filtrar" className="icono-filtrar" />
        <button  className='Regresar' onClick={cerrar} > Regresar</button>
        </div>
      </div>
      <h2>Todos los Servicios</h2>
      {serviciosFiltrados.length > 0 ? (
        <ul>
          {serviciosFiltrados.map((servicio) => (
            <li key={servicio.id}>
              <div className="columna">
                {editando === servicio.id ? (
                  <>
                    <div><strong>Cliente:</strong> <input type="text" name="nombre_cliente" defaultValue={servicio.nombre_cliente} onChange={handleInputChange} /></div>
                    <div><strong>Vehículo:</strong> <input type="text" name="tipo_vehiculo" defaultValue={servicio.tipo_vehiculo} onChange={handleInputChange} /> - {servicio.placa}</div>
                    <div><strong>Servicio:</strong>
                      <select name="producto_servicio_id" value={formData.producto_servicio_id || servicio.producto_servicio_id} onChange={handleServicioChange}>
                        {productosServicios.map((s) => (
                          <option key={s.id} value={s.id}>
                            {s.nombre} - ${s.precio}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div><strong>Precio:</strong> <input type="number" name="precio_servicio" value={formData.precio_servicio || servicio.precio_servicio} readOnly /></div>
                  </>
                ) : (
                  <>
                    <div><strong>Cliente:</strong> {servicio.nombre_cliente}</div>
                    <div><strong>Vehículo:</strong> {servicio.tipo_vehiculo} - {servicio.placa}</div>
                    <div><strong>Servicio:</strong> {servicio.nombre_servicio}</div>
                    <div><strong>Precio:</strong> ${servicio.precio_servicio}</div>
                  </>
                )}
              </div>
              <div className="columna">
                {editando === servicio.id ? (
                  <>
                    <div><strong>Servicio Adicional:</strong> <input type="text" name="servicio_adicional_nombre" defaultValue={servicio.servicio_adicional_nombre} onChange={handleInputChange} /></div>
                    <div><strong>Precio Adicional:</strong> <input type="number" name="servicio_adicional_precio" defaultValue={servicio.servicio_adicional_precio} onChange={handleInputChange} /></div>
                    <div><strong>Estado:</strong>
                      <select name="estado" defaultValue={servicio.estado} onChange={handleInputChange}>
                        <option value="en proceso">En Proceso</option>
                        <option value="terminado">Terminado</option>
                      </select>
                    </div>
                    <div><strong>Total:</strong> ${formData.total || servicio.total}</div>
                  </>
                ) : (
                  <>
                    <div><strong>Servicio Adicional:</strong> {servicio.servicio_adicional_nombre}</div>
                    <div><strong>Precio Adicional:</strong> ${servicio.servicio_adicional_precio}</div>
                    <div><strong>Estado:</strong> {servicio.estado}</div>
                    <div><strong>Total:</strong> ${servicio.total}</div>
                  </>
                )}
              </div>
              <div className="columna">
                <div><strong>Fecha de Creación:</strong> {new Date(servicio.created_at).toLocaleDateString()}</div>
                <div><strong>Lavador:</strong>
                  {editando === servicio.id ? (
                    <select name="lavador_id" value={formData.lavador_id || servicio.lavador_id} onChange={handleLavadorChange}>
                      {lavadores.map((l) => (
                        <option key={l.id} value={l.id}>
                          {l.nombre}
                        </option>
                      ))}
                    </select>
                  ) : (
                    servicio.lavador_nombre
                  )}
                </div>
                <div className="producto-acciones">
                  {editando === servicio.id ? (
                    <button className='btnFormularioProductos' onClick={() => handleActualizar(servicio.id)}>Actualizar</button>
                  ) : (
                    <button className='btnFormularioProductos' onClick={() => {
                      setEditando(servicio.id);
                      setFormData({
                        // No incluir cliente_id y vehiculo_id aquí
                        cliente_id: servicio.cliente_id,
                        vehiculo_id: servicio.vehiculo_id,
                        producto_servicio_id: servicio.producto_servicio_id,
                        precio_servicio: servicio.precio_servicio,
                        servicio_adicional_precio: servicio.servicio_adicional_precio, // Asegurarse de incluir el precio del servicio adicional
                        total: servicio.total
                      });
                    }}>Editar</button>
                  )}
                  <button className='btnFormularioProductos' onClick={() => eliminarServicio(servicio.id)}>Eliminar</button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p>No hay servicios disponibles</p>
      )}
    </div>
  );
}

export default Todos;